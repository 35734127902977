@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;700&display=swap');
@import url('https://fonts.googleapis.com/css?family=Montserrat:700&display=swap');

* {
  font-family: 'Open Sans';
}

div {
  color: #191919;
}
/* span {
  color: #191919;
} */
p {
  color: #191919;
} 

.sidebar {
  transition: .3s;
  flex: .3;
}
.sidebar-closed {
  transition: .3s;
  flex: 0;
  width: 0px;
}
.right-side-small {
  flex: .7;
  transition: .3s;
  /* overflow: auto;
  position: relative; */
}
.right-side-big {
  flex: 1;
  transition: .3s;
  /* overflow: auto; */
  /* position: relative; */
}

.hideme {
  display: none;
  visibility: hidden;
  width: 0px;
}

.fadeHover {
  opacity: 1;
  transition: .1s;
}
.fadeHover:hover {
  opacity: .8;
  transition: .1s;
}

.minimize-button {
  width: 30px;
  right: -31px;
  transition: .2s;
  opacity: .5;
}
.minimize-button:hover {
  width: 40px;
  right: -41px;
  transition: .2s;
  opacity: 1;
}

.section-arrow-container {
  opacity: .6;
  transition: .15s;
  cursor: pointer;
}
.section-arrow-container:hover {
  opacity: 1;
  transition: .15s;
}

.boldHover {
  opacity: .7;
}
.boldHover:hover {
  opacity: 1;
}

.itemCard{
  /* height: 500px; */
  transition: .2s;
}
.itemCardClosed{
  transition: .2s;
  height: 0px;
}

.closeButton {
  opacity: .9;
  border: 1px solid #ddd
}
.closeButton:hover {
  opacity: 1;
}

.siteSelectCard {
  margin-top: 0px;
  margin-bottom: 30px;
  transition: .3s;

}

.siteSelectCard:hover {
  margin-top: -5px;
  margin-bottom: 35px;
  transition: .3s;

}

.linkItem {
  color: #1890FF;
  cursor: pointer;

}
.linkItem:hover {
  text-decoration-line: underline;
}

.pageSectionCard {
  margin-top: 0px;
  margin-bottom: 20px;
  transition: .2s;
  box-shadow: 0 0 6px 3px rgba(0,0,0,.03);
  border: 1px solid #ededed;
}

.pageSectionCard:hover {
  margin-top: -5px;
  margin-bottom: 25px;
  transition: .2s;
  /* box-shadow: 0 0 9px 4px rgba(0,0,0,.08); */

}

.highlightHover {
  opacity: .6;
}
.highlightHover:hover {
  opacity: 1;
}

.disableHighlight {
  -webkit-tap-highlight-color: transparent;
  -webkit-user-select: none;
}

.ant-tabs-content-top {
  height: 100%;
}